// https://getbootstrap.com/docs/4.1/getting-started/theming/
@use "sass:color";

$theme-colors: (
  "primary": #007f95,
  "secondary": #cce5ea,
  "tertiary": #00a5b4,
  "light": #edf6f8,
  "dark": #000000,
);

$font-family-sans-serif: "Helvetica Neue", sans-serif;

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Md.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
  font-weight: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
  font-weight: oblique;
}

$navbarHeight: 3.625rem;
$subNavbarHeight: 2.125rem;

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "bootstrap/scss/bootstrap.scss";

html {
  height: 100%;
}

body {
  background-color: theme-color("light");
  color: theme-color("primary");
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
}

img {
  height: auto;
  max-width: 100%;
}

.navbar {
  flex-shrink: 0;
  align-content: center;

  img {
    max-height: 2.25rem;
  }

  .navbar-link,
  .btn {
    color: theme-color("light");
    padding: 0.25rem 0.5rem;
  }

  svg {
    fill: white;
  }
}

.sub-navbar {
  flex-shrink: 0;
  background-color: theme-color("light");
  border-bottom: 1px solid theme-color("primary");

  .nav-item {
    font-size: 0.75rem;
    flex-basis: 50%;
    padding-left: 0;

    &.active {
      text-decoration: none;
      background-color: theme-color("secondary");
    }

    a {
      width: 100%;
      text-align: center;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        max-width: map-get($container-max-widths, "sm") / 2;
      }

      @media (min-width: map-get($grid-breakpoints, "md")) {
        max-width: map-get($container-max-widths, "md") / 2;
      }

      @media (min-width: map-get($grid-breakpoints, "lg")) {
        max-width: map-get($container-max-widths, "lg") / 2;
      }

      @media (min-width: map-get($grid-breakpoints, "xl")) {
        max-width: map-get($container-max-widths, "xl") / 2;
      }
    }
  }
}

.nav-item {
  padding-left: 2.5rem;
  text-transform: uppercase;
}

.navbar {
  height: $navbarHeight;
}

.navbar-margin {
  margin-top: $navbarHeight;
}

.sub-navbar {
  position: fixed;
  top: $navbarHeight;
  left: 0;
  right: 0;
}

.sub-navbar-margin {
  margin-top: $subNavbarHeight;
}

h1 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.alert-container {
  position: absolute;
  bottom: 0;
  left: 1rem;
}

.pointer {
  cursor: pointer;
}

.hide-full-content {
  max-height: 8rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;

  &::after {
    content: "";
    position: absolute;
    top: 3rem;
    height: 6rem;
    left: 0;
    right: 0;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0.001), white 50%)
      left repeat;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white 50%) left
      repeat;
  }

  img,
  video,
  .audio-button {
    display: none;
  }
}

.read-more {
  position: absolute;
  width: 100%;
  top: 5rem;
  z-index: 1;
}

.sidebar-close,
.sidebar-close-button {
  position: fixed;
  width: Max(4rem, calc(100% - 46rem));
  left: 0;
  top: 0;
}

.sidebar-close {
  background: theme-color("dark");
  opacity: 0.5;
  height: 100%;
  z-index: 1999;
}

.sidebar-close-button {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2000;
}

.sidebar {
  padding: 1rem 0rem;
  position: fixed;
  width: Min(46rem, calc(100% - 4rem));
  background: theme-color("light");
  right: 0;
  top: 0;
  height: 100%;
  transition: all 0.3s;
  z-index: 1999;

  .sidebar-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;

    @media screen and (max-width: 50rem) {
      flex-direction: column;
    }

    .sidebar-churches {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      flex-grow: 1;

      .nav-link {
        padding: 0.75rem 2rem;
      }
    }

    .sidebar-statics {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;

      .nav-link {
        padding: 0.25rem 1rem;
      }
    }
  }

  svg {
    .a {
      fill: none;
    }

    .b {
      clip-path: url(#a);
    }

    .c {
      fill: #007f95;
    }
  }
}

.church-list-image,
.church-title-image,
.poi-list-image,
.poi-title-image {
  background-color: theme-color("secondary");
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    object-fit: contain;
  }
}

.church-list-item {
  background-color: theme-color("light");
  padding-bottom: 1rem;

  .church-list-name {
    font-size: 1.25rem;
    color: theme-color("dark");
    margin-right: 1rem;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem;
  }
}

.church-show-title {
  margin-top: 0.5rem;
  color: theme-color("dark");
  font-size: 2rem;
  font-weight: normal;
}

.church-show-title-close {
  cursor: pointer;

  svg {
    vertical-align: bottom;
  }
}

.border-bottom {
  border-bottom: 1px solid #80bfca !important;
}

.wlcontainer {
  fill: theme-color("primary");
}

.toggle-detail-box {
  border-bottom: 1px solid theme-color("primary");
}

.detail-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  padding-left: 0.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: color.scale(theme-color("light"), $lightness: 50%);
  color: theme-color("primary");
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
  }

  &.open {
    background-color: theme-color("primary");
    color: white;

    svg {
      fill: white;
    }
  }

  .detail-icon-container {
    margin-right: 0.5rem;
    width: 1.875rem;
    height: 1.25rem;
    display: inline-flex;
    justify-content: center;
  }

  svg {
    height: 1.5rem;
    fill: theme-color("primary");
  }
}

.detail-box-content {
  padding: 1rem 0.5rem;
  background-color: white;
  color: black;
}

.napi-event + .napi-event {
  padding-top: 1rem;
}

.napi-event-title {
  color: #850057;
}

.content {
  color: theme-color("dark");
}

.content,
.detail-box-content {
  leaflet-map {
    height: 80vh;
    display: block;
  }
}

.content-image {
  width: 100%;
  object-fit: contain;
}

.object-fit-contain {
  object-fit: contain !important;
}

.poi-title,
.overlay-title {
  font-weight: bold;
  font-size: 1.25rem;
}

.poi-list-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 1rem;
  background-color: white;

  img {
    object-fit: contain;
  }
}

.groundplan {
  background-color: white;
  width: 100%;
  height: vh(90);
  display: block;

  .leaflet-image-layer {
    object-fit: contain;

    & + .leaflet-image-layer {
      mix-blend-mode: multiply;
    }
  }
}

.groundplan-marker {
  background-color: theme-color("primary");
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.poi-list {
    flex: 0 0 1.5rem;
    height: 1.5rem !important;
    margin-right: 0.75rem;
    margin-top: 0.25rem;
  }
}

.main-map {
  background-color: #7ba0c1;
  width: 100%;
  height: 100%;
  display: block;

  img {
    object-fit: contain;
  }
}

.map {
  height: calc(100% - #{$navbarHeight} - #{$subNavbarHeight});
  width: 100%;
  // to let the sub navbar bottom border be visible
  padding-top: 1px;
}

.map-marker-cluster {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-size: cover;

  &.simple {
    background-image: url("/assets/simple-cluster-marker.svg");
  }

  &.complex {
    background-image: url("/assets/complex-cluster-marker.svg");
  }

  &.region {
    &::after {
      background: url("/assets/region-cluster-addon.svg");
      width: 29px;
      height: 29px;
      position: absolute;
      content: "";
      top: -15px;
      left: -15px;
    }
  }
}

.leaflet-bar {
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-out:hover {
    color: theme-color("primary");
    background-color: theme-color("light");
    border: none;
    font: inherit;
    font-size: 1.5rem;
    line-height: 30px;
    margin-bottom: 0.125rem;
    border-radius: 0.125rem;
  }
}

.leaflet-touch .leaflet-bar {
  border: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: theme-color("dark");
  opacity: 0.3;
  z-index: 2000;
  cursor: pointer;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(#{vh(50)} - 50%));
  max-height: vh(80);
  background-color: white;
  z-index: 2100;
  display: flex;
  flex-direction: column;
  border-bottom: 0.125rem solid theme-color("primary");
  padding: 0.5rem 1.25rem;
  overflow-y: auto;
  cursor: pointer;
  max-width: 80vw;

  // using Min (instead of min), so that sass-compiler ignores it, but still works for browsers
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    max-width: Min(80vw, map-get($container-max-widths, "sm"));
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    max-width: Min(80vw, map-get($container-max-widths, "md"));
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    max-width: Min(80vw, map-get($container-max-widths, "lg"));
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    max-width: Min(80vw, map-get($container-max-widths, "xl"));
  }

  svg {
    fill: theme-color("primary");
  }

  .overlay-title-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-shrink: 0;
  }

  .overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-bottom: 1rem;
    flex-shrink: 0;

    img {
      object-fit: contain;
    }

    .overlay-content-subtext {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      color: theme-color("dark");
    }
  }
}

.audio-button {
  float: right;
  padding-left: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: end;
  cursor: pointer;

  svg {
    width: 4rem;
    height: 4rem;
    fill: theme-color("dark");
  }
}

.audio-player-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  background-color: theme-color("primary");
  color: theme-color("light");

  .headphone-message {
    font-weight: bold;
  }

  svg {
    .a {
      fill: none;
    }

    .b {
      clip-path: url(#a);
    }

    .c {
      fill: #ffffff;
    }
  }
}

.audio-player-padding {
  padding-bottom: 7.5rem;
}

#mapid {
  background-color: theme-color("light");
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
  }
}

.poi-details-title {
  background-color: theme-color("light");
  color: theme-color("dark");
  margin-bottom: 0;
  padding: 1rem 0.5rem;
  font-size: 2rem;
}

.poi-details-tab {
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;

  svg {
    height: 1.25rem;
    fill: white;
  }

  &.knowing {
    background-color: #80bfca;
  }

  &.believing {
    background-color: #4da5b5;
  }

  &.experiencing {
    background-color: #007f95;
  }

  &.active {
    color: theme-color("primary");
    background-color: white;

    svg {
      fill: theme-color("primary");
    }
  }
}

.sidebar-root ~ .page {
  height: vh(100);
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sidebar-root a:active {
  color: theme-color("primary");
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.elph-editor__html-content {
  // `sub`s as first element of a new content block should not have top-margin
  div:first-child > {
    sub:first-child {
      margin-top: -0.75rem;
    }
  }

  sub {
    color: dimgray;
    line-height: 1.25rem;
    display: block;
  }

  a {
    text-decoration: underline;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.fill-primary {
  fill: theme-color("primary") !important;
}

.v-hidden {
  visibility: hidden !important;
}

.difference {
  mix-blend-mode: difference !important;
}

.opa-30 {
  opacity: 0.3;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.consent-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;

  display: flex;
  flex-direction: column;
  justify-content: end;
  animation: 2s linear fadein;
}

.consent {
  padding: 1rem 0.75rem 0.75rem;
  color: theme-color("dark");
  background-color: theme-color("secondary");
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  overflow-y: scroll;

  .checkbox {
    margin-top: 1rem;
    line-height: 0.75rem;

    input {
      width: 1rem;
    }

    label {
      font-size: 1.125rem;
      margin-left: 1rem;
    }
  }

  .btn {
    margin-top: 1rem;
    flex-shrink: 0;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  a {
    font-size: 1.25rem;

    &:first-of-type {
      margin-right: 3rem;
    }
  }
}

.widescreen-image {
  max-width: 100vw;
  overflow-x: scroll;

  img {
    max-width: none;
  }
}

.quiz {
  width: 100%;

  .quiz-counter {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .quiz-navigation {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .quiz-question-container,
  .quiz-summary {
    display: flex;
    flex-direction: column;

    .btn {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 1rem;
    }
  }

  .quiz-question {
    font-weight: bold;
    font-size: 1.25rem;
  }
}

blockquote {
  background-color: theme-color("light");
  margin-left: 1rem;
  padding: 0.5rem 2rem;
}

.association-game--textToText,
.association-game--imageToText {
  .association-game--content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .association-game--targets {
    flex: 1 1 75%;
  }

  .association-game--target {
    display: flex;
    margin: 0.5rem;
    background-color: theme-color("secondary");
  }

  .association-game--option {
    cursor: pointer;
    margin: 0.5rem;
  }

  .association-game--text {
    padding: 1rem 2rem;
    flex: 1 1 auto;
  }
}

.association-game--textToText {
  .association-game--options {
    flex: 1 1 25%;
  }

  .association-game--option,
  .association-game--answer {
    background-color: theme-color("primary");
    color: theme-color("light");
  }
}

.association-game--imageToText {
  .association-game--options {
    width: 3.75rem;
  }

  .association-game--option {
    line-height: 3.6875rem; //fontsize 1.125 * line-height 1.5 + 2 padding
    width: 70%;
  }

  .association-game--answer {
    width: 3.75rem;
    margin: 0.5rem;

    img {
      width: 70%;
      height: auto;
    }
  }
}

.association-game--textToImage {
  width: 100%;
  margin: 0;
  padding: 0;

  .association-game--content {
    position: relative;
    display: flex;
    flex-direction: column;

    // Limit the height of the image for the game to be playable on one screen
    // 11rem are plenty for two rows of text-options
    img {
      max-height: calc(#{vh(100)} - 11rem - #{$navbarHeight});
    }
  }

  .association-game--options {
    padding-top: 2rem;
  }

  .association-game--option {
    cursor: pointer;
    background-color: #fff;
    box-shadow: 1px 1px 6px #888;
    display: inline-block;
    margin: 0 2% 1rem;
    position: relative;
    text-align: center;
    width: 46%;
    z-index: 20;
  }

  .association-game--answer {
    position: absolute;
    left: 14%;
    bottom: 20px;
    width: 72%;
    z-index: 10;
    text-align: center;
    background-color: #fff;
    box-shadow: 1px 1px 6px #888;
    opacity: 0.9;

    &::before {
      content: "✓";
      position: absolute;
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      right: 5px;
      top: calc(50% - 0.9rem);
      font-weight: normal;
      font-size: 130%;
      line-height: 1.1;
      background-color: #dff0d8;
      color: #3c763d;
      border: 2px solid #3c763d;
    }

    .association-game--text {
      padding: 0.5rem 2rem 0.5rem 0.5rem;
    }
  }

  .association-game--text {
    padding: 0.5rem;
  }

  .association-game--info-text {
    z-index: 99;
    padding: 1rem 2rem;
    position: fixed;
    margin: auto;
    width: 80%;
    bottom: 2rem;
    left: 10%;
    background-color: #eee;
    border: 1px solid;
    border-radius: 0.5rem;
  }
}

.association-game--info-text {
  width: 90%;
  font-size: 14px;
  line-height: 18px;
  padding: 1rem 4%;
  background-color: #fff;
  clear: both;
  position: fixed;
  bottom: -200px;
  max-height: 200px;
  overflow: hidden;
  left: 5%;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease;

  &.visible {
    bottom: 0px;
    transition: 0.4s ease;
  }
}

.association-game--success-text {
  width: 90%;
  padding: 1rem 4%;
  background-color: #fff;
  clear: both;
  position: fixed;
  top: -200px;
  max-height: 200px;
  overflow: hidden;
  left: 5%;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease;

  &.visible {
    top: 75px;
    transition: 0.4s ease;
  }
}

.image-gallery {
  display: flex;
  justify-content: center;
  position: relative;

  .image-gallery-arrow-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .image-gallery-arrow {
      display: flex;
      align-items: center;
      height: 100%;
      width: 25%;
      cursor: pointer;

      .image-gallery-arrow-container-icon {
        background-color: theme-color("primary");
        padding: 0.5rem;
        border-radius: 50%;
        mix-blend-mode: normal;

        span {
          mix-blend-mode: normal;
          display: flex;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;

          svg {
            fill: white;
          }
        }
      }

      &.right {
        justify-content: flex-end;

        .image-gallery-arrow-container-icon {
          margin-right: 0.5rem;

          span {
            transform: rotate(90deg);
          }
        }
      }

      &.left {
        justify-content: flex-start;

        .image-gallery-arrow-container-icon {
          margin-left: 0.5rem;

          span {
            transform: rotate(270deg);
          }
        }
      }

      span {
        mix-blend-mode: difference;

        svg .c {
          fill: white;
        }
      }
    }
  }
}

.folding-altar-container {
  position: relative;

  .folding-altar {
    background-color: white;
    min-height: 500px;

    .leaflet-image-layer {
      object-fit: contain;
    }

    .inner-left-image,
    .outer-right-image,
    .opened-once-inner-left-image,
    .opened-twice-inner-left-image,
    .opened-once-outer-right-image,
    .opened-twice-outer-right-image {
      transform-origin: right center;
      backface-visibility: hidden;
      transition: inherit;
    }

    .inner-right-image,
    .outer-left-image,
    .opened-once-inner-right-image,
    .opened-twice-inner-right-image,
    .opened-once-outer-left-image,
    .opened-twice-outer-left-image {
      transform-origin: left center;
      backface-visibility: hidden;
      transition: inherit;
    }
  }

  .folding-altar-button-container {
    display: flex;
    flex-direction: row;
  }

  .folding-altar-button {
    @extend .btn, .btn-light;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    color: theme-color("primary");
    z-index: 800;
  }

  .button-unavailable {
    color: theme-color("secondary");
  }
}

.levite-chair {
  .levite-chair--items {
    display: flex;
    flex-wrap: wrap;

    .levite-chair--item {
      flex: 0 calc(50% - 1rem);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0;
      border-style: solid;
      border-color: theme-color("primary");
      background-color: theme-color("light");
      margin: 0.5rem;

      .levite-chair--item--title {
        padding: 0.5rem;
      }

      .levite-chair--selection-overlay {
        position: absolute;
        background-color: transparentize(theme-color("primary"), 0.4);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .levite-chair--selection-index {
          color: theme-color("primary");
          font-size: 2rem;
          background-color: theme-color("light");
          padding: 1rem 2rem;
          border-radius: 50%;
        }
      }
    }
  }

  button {
    display: block;
  }
}

.tower-game {
  /* height - navbar - page-padding - button-size */
  height: calc(#{vh(100)} - #{$navbarHeight} - 1rem - 3.625rem);

  .dropping {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }

  .export-button {
    margin-top: 1rem;
  }
}

.tg--game {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  /* height - button-size */
  height: calc(100% - 3.625rem);
}

.tg--drawer {
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    padding: 0.25rem 0;
  }

  border-right: 1px solid theme-color("primary");
  padding-right: 2rem;
}

.tg-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0.5rem;
}

.tg-dropzone-empty {
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

@keyframes present-game--glow {
  0% {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  }

  50% {
    filter: drop-shadow(0 0 10px var(--glow-color));
  }

  100% {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  }
}

@mixin present-game--glow($color, $delay) {
  animation-name: present-game--glow;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: #{$delay};
  --glow-color: #{$color};
}

.present-game {
  .present-game--container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 750px;

    &.dragging {
      .present-game--baby {
        filter: drop-shadow(0 0 10px rgba(255, 255, 0, 1));
      }

      .present-game--gold,
      .present-game--incense,
      .present-game--myrrh {
        --glow-color: transparent;
      }
    }

    &.dropping {
      .present-game--baby {
        filter: drop-shadow(0 0 30px rgba(255, 255, 0, 1));
      }
    }
  }

  .present-game--baby {
    position: absolute;
    height: 25%;
    width: 25%;
    left: 35%;
    top: 59%;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  }

  .present-game--gold {
    @include present-game--glow(rgba(238, 238, 238, 1), 2s);
    position: absolute;
    height: 25%;
    width: 25%;
    left: 64.5%;
    top: 38%;
  }

  .present-game--myrrh {
    @include present-game--glow(rgba(33, 33, 33, 1), 1s);
    position: absolute;
    height: 25%;
    width: 25%;
    left: 37.5%;
    top: 43%;
  }

  .present-game--incense {
    @include present-game--glow(rgba(238, 208, 122, 1), 0s);
    position: absolute;
    height: 25%;
    width: 25%;
    left: 11.5%;
    top: 37%;
  }

  .present-game--modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize(theme-color("primary"), 0.2);
    padding: #{vh(5)} 5vw;
    z-index: 1050;

    .present-game--modal-content {
      @extend .container;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      background-color: theme-color("light");
      overflow: auto;

      ul {
        padding-top: 1rem;
        padding-left: 1.5rem;
      }

      small {
        align-self: flex-end;
        font-size: 0.7rem;
      }
    }
  }
}

.opening-hours-season {
  & + .opening-hours-season {
    padding-top: 2rem;
  }

  .opening-hours-season--name {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .opening-hours-season--date,
  .opening-hours-season--special-title {
    font-weight: bold;
    padding-bottom: 1.3rem;
  }

  .opening-hours-season--special {
    padding-top: 1rem;
  }

  .opening-hours-season--table {
    line-height: 1.5rem;
  }

  .opening-hours-season--day-col {
    padding-right: 2rem;
  }
}

tangram-game {
  width: 100%;
  height: 80vh;
  display: block;
  border: 1px solid theme-color("primary");
  position: relative;

  .reset-btn {
    position: absolute;
    // 2.5rem = 40px = button height + 2px margin
    top: calc(100% - 2.5rem);
    left: 0.125rem;
  }

  .winning-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 2s;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 1.5rem;
    border-color: white;
  }
}

searching-game {
  width: 100%;
  display: block;
  border: 1px solid theme-color("primary");
  position: relative;

  .reset-btn {
    position: absolute;
    // 2.5rem = 40px = button height + 2px margin
    top: calc(100% - 2.5rem);
    left: 0.125rem;
  }

  .winning-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 2s;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 1.5rem;
    border-color: white;
  }
}

.window-color {
  .leaflet-container {
    background-color: unset;
    width: 100%;
    // 8rem should be enough for the color pickers and a little
    height: calc(#{vh(100)} - 8rem - #{$navbarHeight});
  }

  .window-color--colors {
    display: flex;
  }

  .window-color--pickers {
    flex-grow: 1;
  }

  .window-color--preview {
    width: 5rem;
    border: solid 2px black;
    margin-left: 0.5rem;
    background-color: var(--color);
  }

  .window-color--picker {
    position: relative;
    width: 100%;
    height: 1rem;

    &.hue {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(red),
        color-stop(17%, #ff0),
        color-stop(33%, lime),
        color-stop(50%, cyan),
        color-stop(66%, blue),
        color-stop(83%, #f0f),
        to(red)
      );
      background: linear-gradient(
        to right,
        red 0%,
        #ff0 17%,
        lime 33%,
        cyan 50%,
        blue 66%,
        #f0f 83%,
        red 100%
      );
    }

    &.saturation {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(var(--color)),
        to(white)
      );
      background: linear-gradient(to right, var(--color) 0%, white 100%);
    }

    &.lightning {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(white),
        color-stop(50%, var(--color)),
        to(black)
      );
      background: linear-gradient(
        to right,
        white 0%,
        var(--color) 50%,
        black 100%
      );
    }
  }
}

back-to-top {
  svg {
    scale: 1.3;
    fill: white;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: theme-color("primary");
    border-radius: 2rem;
  }

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  z-index: 1000;
}

back-to-top + div {
  display: none;
}

// range inputs for windowcolor colorpicker
input[type="range"] {
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  border: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  width: 1rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-appearance: none;
  border: 2px solid black;
  border-radius: 0;
  margin-top: 0;
}

input[type="range"]::-moz-range-track {
  border: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 1rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-appearance: none;
  border: 2px solid black;
  border-radius: 0;
  margin-top: 0;
}

input[type="range"]::-ms-thumb {
  width: 1rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-appearance: none;
  border: 2px solid black;
  border-radius: 0;
  margin-top: 0;
}

input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

input[type="range"]::-ms-fill-lower {
  border: 0;
}

input[type="range"]::-ms-fill-upper {
  border: 0;
}

@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

button {
  // When using an interaction that is not a link you should use a
  // button for accessibility. This is so it doesn't look like a button
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: inherit;
  padding-left: 0;
  padding-right: 0;
  line-height: normal;
}

#__parcel__error__overlay__ {
  & > div {
    white-space: pre;
    overflow: scroll;
  }
}

.image-gallery-slide {
  flex-grow: 1;
  height: 150px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
  }

  svg {
    mix-blend-mode: difference;

    polygon {
      fill: white; //explicitly white for blend-mode
    }
  }

  .image-gallery-button-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .image-gallery-button {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 100%;
      width: 25%;
      cursor: pointer;

      &.button-left {
        justify-content: flex-start;
        padding-left: 0.5rem;

        svg {
          mix-blend-mode: normal;
        }
      }

      &.button-right {
        justify-content: flex-end;
        padding-right: 0.5rem;

        svg {
          mix-blend-mode: normal;
        }
      }
    }
  }
}
